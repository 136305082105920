import RateChange from "./RateChange";
import { round } from "./helpers";

export default function RatesRow({ rate }) {

    const isOutOfDate = (timestamp) => {
        const oneHourAgo = new Date(new Date() - 1000 * 60 * 60);
        return new Date(timestamp) < oneHourAgo;
    };

    const outOfDate = isOutOfDate(rate.timestamp);
    const updateMessage = outOfDate
        ? "Last updated at " + new Date(rate.timestamp)
        : null;

    return (
        <tr>
            <td className={outOfDate ? "table-danger" : ""} title={updateMessage}>
                {rate.href ? (
                    <a href={rate.href} target="_blank">
                        {rate.name}
                    </a>
                ) : (
                    <span>{rate.name}</span>
                )}
            </td>
            <td>{round(rate.value)}</td>
            <RateChange currentValue={rate.value} previousValue={rate.value1DayAgo} />
            <RateChange currentValue={rate.value} previousValue={rate.value1WeekAgo} />
            <RateChange currentValue={rate.value} previousValue={rate.value1MonthAgo} />
            <RateChange currentValue={rate.value} previousValue={rate.value3MonthsAgo} />
            <RateChange currentValue={rate.value} previousValue={rate.value6MonthsAgo} />
            <RateChange currentValue={rate.value} previousValue={rate.value1YearAgo} />
            <RateChange currentValue={rate.value} previousValue={rate.value2YearsAgo} years={2} />
            <RateChange currentValue={rate.value} previousValue={rate.value5YearsAgo} years={5} />
            <RateChange currentValue={rate.value} previousValue={rate.value10YearsAgo} years={10} />
        </tr>
    );
}
