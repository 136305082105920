{
    "rates": [
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.1902043Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "USDZAR",
            "timestamp": "2024-10-10T19:00:15.1902043Z",
            "change10Year": 58.53,
            "change1Day": -0.76,
            "change1Month": -2.27,
            "change1Week": 0.19,
            "change1Year": -7.73,
            "change2Year": -3.07,
            "change3Months": -3.17,
            "change5Year": 15.38,
            "change6Months": -6.59,
            "change7Year": 27.19,
            "href": "https://finance.yahoo.com/quote/ZAR%3DX/chart",
            "name": "USDZAR",
            "order": 0,
            "ticker": "USDZAR",
            "value": 17.53965
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.1702163Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "ZARUSD",
            "timestamp": "2024-10-10T19:00:15.1702163Z",
            "change10Year": -36.92,
            "change1Day": 0.77,
            "change1Month": 2.32,
            "change1Week": -0.19,
            "change1Year": 8.38,
            "change2Year": 3.17,
            "change3Months": 3.27,
            "change5Year": -13.33,
            "change6Months": 7.05,
            "change7Year": -21.38,
            "href": "https://finance.yahoo.com/quote/ZAR%3DX/chart",
            "name": "ZARUSD",
            "order": 1,
            "ticker": "ZARUSD",
            "value": 0.0570136804326198
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.192203Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "GBPZAR",
            "timestamp": "2024-10-10T19:00:15.192203Z",
            "change10Year": 28.33,
            "change1Day": -0.91,
            "change1Month": -2.52,
            "change1Week": -0.42,
            "change1Year": -2.03,
            "change2Year": 14.4,
            "change3Months": -1.67,
            "change5Year": 23.21,
            "change6Months": -2.8,
            "change7Year": 26.18,
            "href": "https://finance.yahoo.com/quote/GBPZAR%3DX/chart",
            "name": "GBPZAR",
            "order": 2,
            "ticker": "GBPZAR",
            "value": 22.885018103532637
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.2711558Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "EURZAR",
            "timestamp": "2024-10-10T19:00:15.2711558Z",
            "change10Year": 36.43,
            "change1Day": -0.94,
            "change1Month": -3.16,
            "change1Week": -0.86,
            "change1Year": -5,
            "change2Year": 8.88,
            "change3Months": -2.4,
            "change5Year": 14.61,
            "change6Months": -5.06,
            "change7Year": 18.2,
            "href": "https://finance.yahoo.com/quote/EURZAR%3DX/chart",
            "name": "EURZAR",
            "order": 3,
            "ticker": "EURZAR",
            "value": 19.151434748427675
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.28115Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "ZARMUR",
            "timestamp": "2024-10-10T19:00:15.28115Z",
            "change1Day": 0.96,
            "change1Month": 2.45,
            "change1Week": -0.75,
            "change1Year": 13.07,
            "change2Year": 3.68,
            "change3Months": 1.83,
            "change6Months": 7.03,
            "href": "https://www.xe.com/currencycharts/?from=ZAR&to=MUR",
            "name": "ZARMUR",
            "order": 4,
            "ticker": "ZARMUR",
            "value": 2.6289011468301817
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.1692172Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "USDMUR",
            "timestamp": "2024-10-10T19:00:15.1692172Z",
            "change10Year": 52.7,
            "change1Day": 0.2,
            "change1Month": 0.13,
            "change1Week": -0.56,
            "change1Year": 7.77,
            "change2Year": 3.97,
            "change3Months": -1.39,
            "change5Year": 31.26,
            "change6Months": -0.02,
            "change7Year": 40.84,
            "href": "https://finance.yahoo.com/quote/MUR%3DX/chart",
            "name": "USDMUR",
            "order": 5,
            "ticker": "USDMUR",
            "value": 46.110006
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.1692172Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "SPY",
            "timestamp": "2024-10-10T19:00:15.1692172Z",
            "change10Year": 201.91,
            "change1Day": -0.15,
            "change1Month": 4.82,
            "change1Week": 1.31,
            "change1Year": 32.38,
            "change2Year": 59.78,
            "change3Months": 2.48,
            "change5Year": 96.17,
            "change6Months": 11.89,
            "change7Year": 125.93,
            "href": "https://seekingalpha.com/symbol/SPY",
            "name": "S&P 500",
            "order": 6,
            "ticker": "SPY",
            "value": 575.2505
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.2521675Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "QQQ",
            "timestamp": "2024-10-10T19:00:15.2521675Z",
            "change10Year": 421.3,
            "change1Day": -0.03,
            "change1Month": 7.34,
            "change1Week": 2.23,
            "change1Year": 33.57,
            "change2Year": 84.8,
            "change3Months": -2.12,
            "change5Year": 160.93,
            "change6Months": 12.31,
            "change7Year": 233.55,
            "href": "https://seekingalpha.com/symbol/QQQ",
            "name": "QQQ",
            "order": 7,
            "ticker": "QQQ",
            "value": 492.3189
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.1662184Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "SCHD",
            "timestamp": "2024-10-10T19:00:15.1662184Z",
            "change10Year": 125.65,
            "change1Day": -0.31,
            "change1Month": 1.72,
            "change1Week": 0.2,
            "change1Year": 19.41,
            "change2Year": 25.4,
            "change3Months": 8.49,
            "change5Year": 57.15,
            "change6Months": 8.13,
            "change7Year": 76.97,
            "href": "https://seekingalpha.com/symbol/SCHD",
            "name": "SCHD",
            "order": 8,
            "ticker": "SCHD",
            "value": 84.485
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.2371763Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "AAPL",
            "timestamp": "2024-10-10T19:00:15.2371763Z",
            "change10Year": 807.06,
            "change1Day": -0.35,
            "change1Month": 3.78,
            "change1Week": 1.22,
            "change1Year": 28.05,
            "change2Year": 62.67,
            "change3Months": -1.96,
            "change5Year": 297.1,
            "change6Months": 36.14,
            "change7Year": 486.07,
            "href": "https://seekingalpha.com/symbol/AAPL",
            "name": "AAPL",
            "order": 9,
            "ticker": "AAPL",
            "value": 228.42
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.3900852Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "MSFT",
            "timestamp": "2024-10-10T19:00:15.3900852Z",
            "change10Year": 843.3,
            "change1Day": -0.45,
            "change1Month": 0.27,
            "change1Week": -0.29,
            "change1Year": 26.48,
            "change2Year": 81.17,
            "change3Months": -10.92,
            "change5Year": 198.59,
            "change6Months": -1.87,
            "change7Year": 444.42,
            "href": "https://seekingalpha.com/symbol/MSFT",
            "name": "MSFT",
            "order": 10,
            "ticker": "MSFT",
            "value": 415.335
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.4030778Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "NVDA",
            "timestamp": "2024-10-10T19:00:15.4030778Z",
            "change10Year": 31739.76,
            "change1Day": 1.36,
            "change1Month": 24.07,
            "change1Week": 9.18,
            "change1Year": 192.86,
            "change2Year": 1049.31,
            "change3Months": -0.58,
            "change5Year": 2831.21,
            "change6Months": 54.1,
            "change7Year": 2739.68,
            "href": "https://seekingalpha.com/symbol/NVDA",
            "name": "NVDA",
            "order": 11,
            "ticker": "NVDA",
            "value": 134.125
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.4060763Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "AMD",
            "timestamp": "2024-10-10T19:00:15.4060763Z",
            "change10Year": 5905.51,
            "change1Day": -4.54,
            "change1Month": 14.36,
            "change1Week": 0.31,
            "change1Year": 49.85,
            "change2Year": 182.56,
            "change3Months": -11.2,
            "change5Year": 475.58,
            "change6Months": -2.27,
            "change7Year": 1092.34,
            "href": "https://seekingalpha.com/symbol/AMD",
            "name": "AMD",
            "order": 12,
            "ticker": "AMD",
            "value": 163.35
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.4010787Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "TSLA",
            "timestamp": "2024-10-10T19:00:15.4010787Z",
            "change10Year": 1432.29,
            "change1Day": 0.17,
            "change1Month": 7,
            "change1Week": 0.56,
            "change1Year": -8.2,
            "change2Year": 8.54,
            "change3Months": -8.07,
            "change5Year": 1383.27,
            "change6Months": 40.9,
            "change7Year": 920.88,
            "href": "https://seekingalpha.com/symbol/TSLA",
            "name": "TSLA",
            "order": 13,
            "ticker": "TSLA",
            "value": 242.01
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.4110732Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "MSTR",
            "timestamp": "2024-10-10T19:00:15.4110732Z",
            "change10Year": 1139.15,
            "change1Day": -3.06,
            "change1Month": 41.04,
            "change1Week": 11.9,
            "change1Year": 437.06,
            "change2Year": 728.68,
            "change3Months": 40.04,
            "change5Year": 1174.03,
            "change6Months": 16.76,
            "change7Year": 1281.56,
            "href": "https://seekingalpha.com/symbol/MSTR",
            "name": "MSTR",
            "order": 14,
            "ticker": "MSTR",
            "value": 182.849039
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.1592227Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "XAUUSD",
            "timestamp": "2024-10-10T19:00:15.1592227Z",
            "change10Year": 114.76,
            "change1Day": 0.54,
            "change1Month": 4.26,
            "change1Week": -1.22,
            "change1Year": 41.07,
            "change2Year": 56.62,
            "change3Months": 10.57,
            "change5Year": 74.81,
            "change6Months": 12.31,
            "change7Year": 102.79,
            "href": "https://www.xe.com/currencycharts/?from=XAU&to=USD",
            "name": "Gold",
            "order": 15,
            "ticker": "XAUUSD",
            "value": 2623.7078238967306
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.2331788Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "XAGUSD",
            "timestamp": "2024-10-10T19:00:15.2331788Z",
            "change10Year": 79.38,
            "change1Day": 1.83,
            "change1Month": 9.18,
            "change1Week": -3.18,
            "change1Year": 42.22,
            "change2Year": 58.24,
            "change3Months": 0.6,
            "change5Year": 76.33,
            "change6Months": 11.21,
            "change7Year": 80.38,
            "href": "https://www.xe.com/currencycharts/?from=XAG&to=USD",
            "name": "Silver",
            "order": 16,
            "ticker": "XAGUSD",
            "value": 31.038000755464935
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.1892047Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "BTCUSD",
            "timestamp": "2024-10-10T19:00:15.1892047Z",
            "change10Year": 16307.52,
            "change1Day": -3.04,
            "change1Month": 2.9,
            "change1Week": -2.36,
            "change1Year": 116.57,
            "change2Year": 209.92,
            "change3Months": 2.74,
            "change5Year": 590.89,
            "change6Months": -15.94,
            "change7Year": 1140.56,
            "href": "https://coinmarketcap.com/currencies/bitcoin/",
            "name": "BTCUSD",
            "order": 17,
            "ticker": "BTCUSD",
            "value": 59323.36360520054
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.2521675Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "ETHUSD",
            "timestamp": "2024-10-10T19:00:15.2521675Z",
            "change1Day": -2.53,
            "change1Month": -1.74,
            "change1Week": -0.04,
            "change1Year": 49.83,
            "change2Year": 81.84,
            "change3Months": -24.29,
            "change5Year": 1125.36,
            "change6Months": -33.73,
            "href": "https://coinmarketcap.com/currencies/ethereum/",
            "name": "ETHUSD",
            "order": 18,
            "ticker": "ETHUSD",
            "value": 2348.513351988988
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.3161291Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "SOLUSD",
            "timestamp": "2024-10-10T19:00:15.3161291Z",
            "change1Day": -2.84,
            "change1Month": 0.64,
            "change1Week": -0.18,
            "change1Year": 517.71,
            "change2Year": 327.09,
            "change3Months": -3.81,
            "change6Months": -21.31,
            "href": "https://coinmarketcap.com/currencies/solana/",
            "name": "SOLUSD",
            "order": 19,
            "ticker": "SOLUSD",
            "value": 136.54493070126836
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.2561648Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "ZECUSD",
            "timestamp": "2024-10-10T19:00:15.2561648Z",
            "change1Day": 9.73,
            "change1Month": 3.25,
            "change1Week": 24.27,
            "change1Year": 25.06,
            "change2Year": -39.55,
            "change3Months": 36.06,
            "change5Year": -15.15,
            "change6Months": 17.86,
            "href": "https://coinmarketcap.com/currencies/zcash/",
            "name": "ZECUSD",
            "order": 20,
            "ticker": "ZECUSD",
            "value": 31.87142660493824
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.3970817Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "LTCUSD",
            "timestamp": "2024-10-10T19:00:15.3970817Z",
            "change10Year": 1577.92,
            "change1Day": -1.81,
            "change1Month": 3.8,
            "change1Week": 0.68,
            "change1Year": 0.41,
            "change2Year": 21.1,
            "change3Months": -4.56,
            "change5Year": 10.57,
            "change6Months": -33.94,
            "change7Year": 25.96,
            "href": "https://coinmarketcap.com/currencies/litecoin/",
            "name": "LTCUSD",
            "order": 21,
            "ticker": "LTCUSD",
            "value": 63.885071661884346
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.4760339Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "XRPUSD",
            "timestamp": "2024-10-10T19:00:15.4760339Z",
            "change1Day": -0.39,
            "change1Month": -2.66,
            "change1Week": 0.91,
            "change1Year": 5.88,
            "change2Year": 5.97,
            "change3Months": 19.97,
            "change5Year": 93.59,
            "change6Months": -14.71,
            "href": "https://coinmarketcap.com/currencies/xrp/",
            "name": "XRPUSD",
            "order": 22,
            "ticker": "XRPUSD",
            "value": 0.5267066844232112
        },
        {
            "etag": "W/\"datetime'2024-10-10T19%3A00%3A15.469038Z'\"",
            "partitionKey": "readmodel",
            "rowKey": "XLMUSD",
            "timestamp": "2024-10-10T19:00:15.469038Z",
            "change1Day": -0.96,
            "change1Month": -5.56,
            "change1Week": -1.63,
            "change1Year": -14.88,
            "change2Year": -28.28,
            "change3Months": 1.45,
            "change5Year": 43.36,
            "change6Months": -31.51,
            "href": "https://coinmarketcap.com/currencies/stellar/",
            "name": "XLMUSD",
            "order": 23,
            "ticker": "XLMUSD",
            "value": 0.08880459324635627
        }
    ]
}