﻿import { useState } from "react";
import { getRates } from "./rates-service";

export function useRates() {
    const [rates, setRates] = useState([]);
    const [loading, setLoading] = useState(false);

    const update = async () => {
        setLoading(true);
        const response = await getRates();
        setRates(response.rates);
        setLoading(false);
    }

    return [rates, loading, update];
}