import { useState, useEffect } from "react";
import RatesTable from "./components/RatesTable";
import { useRates } from "./useRates";

export default function App() {
    const [rates, loading, update] = useRates();

    useEffect(() => {
        update();
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-1"></div>

                <div className="col-md-10">
                    <RatesTable rates={rates} loading={loading} update={update} />
                </div>

                <div className="col-md-1"></div>
            </div>
        </div>
    );
}
