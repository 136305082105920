﻿import settings from "./settings";
import exampleRates from "./example-rates.json";

export function getRates() {
    const fetchExampleRates = () => {
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(exampleRates), 1000);
        });
    };

    const fetchRates = async () => {
        const res = await fetch("/api/rates");
        return await res.json();
    };

    return settings.useExampleRates ? fetchExampleRates() : fetchRates();
}